export const Route = {
  DASHBOARD: 'dashboard',
  REGISTER: 'register',
  REGISTER_SUCCESS: 'register_success',
  COMPANY_USER_REGISTER_SUCCESS: 'companies/register_success',
  ERROR: 'error',
  PROJECTS: 'projects',
  PIPELINES: 'pipelines',
  PAYMENTS: 'payments',
  STATISTICS: 'statistics',
  STATISTICS_USAGE: 'statistics/usage',
  USERS: 'users',
  ARTIFACTS: 'artifacts',
  JOB_RUNS: 'job_runs',
  POSITIONS: 'positions',
  TRANSFORM: 'transform',
  COMPANY: 'company',
}

export const Routes = {
  [Route.DASHBOARD]: '/dashboard',
  [Route.ERROR]: '/error',
  [Route.REGISTER]: '/register',
  [Route.REGISTER_SUCCESS]: '/register_success',
  [Route.COMPANY_USER_REGISTER_SUCCESS]: '/companies/register_success',
  [Route.PROJECTS]: '/projects',
  [Route.PIPELINES]: '/pipelines',
  [Route.PAYMENTS]: '/payments',
  [Route.STATISTICS]: '/statistics',
  [Route.STATISTICS_USAGE]: '/statistics/usage',
  [Route.USERS]: '/users',
  [Route.ARTIFACTS]: '/artifacts',
  [Route.JOB_RUNS]: '/job_runs',
  [Route.POSITIONS]: '/positions',
  [Route.TRANSFORM]: '/transform',
  [Route.COMPANY]: '/company',
}

export const BasicRoutes = {
  DASHBOARD: '/dashboard',
  ERROR: '/error',
  REGISTER: '/register',
  REGISTER_SUCCESS: '/register_success',
  COMPANY_USER_REGISTER_SUCCESS: '/companies/register_success',
  PROJECTS: '/projects',
  PIPELINES: '/pipelines',
  PAYMENTS: '/payments',
  STATISTICS: '/statistics',
  STATISTICS_USAGE: '/statistics/usage',
  USERS: '/users',
  ARTIFACTS: '/artifacts',
  JOB_RUNS: '/job_runs',
  POSITIONS: '/positions',
  TRANSFORM: '/transform',
  COMPANY: '/company',
}

export function getBasicRouteUrl (route) {
  return Routes[route] || route
}
