import React from 'react'
import { Typography } from '@material-ui/core'
import { Trans } from 'react-i18next'
import { routeTerms } from 'utils/routing'

const TermsAndConditions = props => {
  return <Typography variant='body2' component='a' href={routeTerms()} target='_blank' align='center'>
    <Trans i18nKey='termsAndConditions'/>
  </Typography>
}

export default TermsAndConditions
