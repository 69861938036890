import React from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { path } from 'ramda'
import Loadable from 'react-loadable'
import { withNamespaces } from 'react-i18next'
// Project deps
import withRoot from 'HOC/withRoot'
import PageLoader from 'components/PageLoader'
import AcceptTermsDialog from 'components/AcceptTermsDialog'
import AcceptUpdateDialog from 'components/AcceptUpdateDialog'
import AppActions from 'modules/app/actions'
import GrantsActions from 'modules/grants/actions'
import CompaniesActions from 'modules/companies/actions'
import UsersActions from 'modules/users/actions'
import UrlActions from 'modules/url/actions'
import { isAdmin, isLoggedIn, getUsers, getLoggedUser, isAcceptTermsFormOpen } from 'modules/users/selectors'
import { isConstantsLoaded } from 'modules/app/selectors'
import { createUrl } from 'utils/url'
import { checkVersion } from 'utils/clear'
import { getCookie } from 'utils/cookies'
import { getCompanyId, getCompanyPermissions, getUserPermissions, isUserCompanyCustomer } from 'utils/company'
// Local deps
import PrivateRoute from './PrivateRoute'
import RegisterRoute from './RegisterRoute'
import AdminRoute from './AdminRoute'
// Pages
import ErrorPage from 'pages/Error'
import UpdateCRSDialog from 'components/UpdateCRSDialog'
import { lidarmillCookies } from 'config'
import UpdateInfoDialog from 'components/UpdateInfoDialog'
import CheckTokenDialog from 'components/App/CheckToken'
import { axiosAccounts } from 'utils/axios'
import { routeRegister } from 'utils/routing'
import { inIframe } from 'utils/browser'
import SetToken from 'pages/SetToken'
import RemoveCookies from 'pages/RemoveCookies'
import { getStorageItem, setStorageItem } from 'utils/localStorage'
const ShareProjects = Loadable({ loader: () => import('pages/ShareProjects'), loading: PageLoader, delay: 50 })
const Dashboard = Loadable({ loader: () => import('pages/Dashboard'), loading: PageLoader, delay: 50 })
const Login = Loadable({ loader: () => import('pages/Login'), loading: PageLoader, delay: 50 })
const RegisterSuccess = Loadable({ loader: () => import('pages/RegisterSuccess'), loading: PageLoader, delay: 50 })
const CompanyUserRegisterSuccess = Loadable({ loader: () => import('pages/CompanyUserRegisterSuccess'), loading: PageLoader, delay: 50 })
const Recover = Loadable({ loader: () => import('pages/Recover'), loading: PageLoader, delay: 50 })
const Project = Loadable({ loader: () => import('pages/Project'), loading: PageLoader, delay: 50 })
const User = Loadable({ loader: () => import('pages/User'), loading: PageLoader, delay: 50 })
const Company = Loadable({ loader: () => import('pages/Company'), loading: PageLoader, delay: 50 })
const Statistics = Loadable({ loader: () => import('pages/Statistics'), loading: PageLoader, delay: 50 })
const Artifact = Loadable({ loader: () => import('pages/Artifact'), loading: PageLoader, delay: 50 })
const Pipeline = Loadable({ loader: () => import('pages/Pipeline'), loading: PageLoader, delay: 50 })
const JobRun = Loadable({ loader: () => import('pages/JobRun'), loading: PageLoader, delay: 50 })
const DropboxToken = Loadable({ loader: () => import('pages/Dropbox'), loading: PageLoader, delay: 50 })
const TransformationCRS = Loadable({ loader: () => import('pages/TransformationCRS'), loading: PageLoader, delay: 50 })
const EmailConfirmation = Loadable({ loader: () => import('pages/EmailConfirmation'), loading: PageLoader, delay: 50 })
const Positions = Loadable({ loader: () => import('pages/Positions'), loading: PageLoader, delay: 50 })

class App extends React.Component {
  state = {
    historyListener: null,
    checkUserInfoUpdatedTimeout: null,
    updateDialogOpen: false,
    updateInfoDialogOpen: false,
    extra: {},
  }

  componentDidMount () {
    const {
      history,
      onUrlChange,
      isLoggedIn,
      getMe,
      getUserReleases,
      getGCPTemplate,
      getProducts,
    } = this.props
    if (!inIframe()) {
      this.setState({
        historyListener: history.listen(location => {
          onUrlChange(createUrl(location))
        }),
      })
      // logout
      checkVersion(() => {
        if (isLoggedIn) {
          this.update()
          getMe()
          getUserReleases()
          this.checkAcceptedForms()
          this.checkUserInfo()
        }
      })
      getProducts()
      getGCPTemplate()
      this.updateSubscriptionStatusInterval()
    }
  }

  checkAcceptedForms = () => {
    setTimeout(() => this.checkAcceptedTerms(), 1000)
    // setTimeout(() => this.checkAcceptedUpdate(), 1000)
  }

  // Updating subscription status every 2 minutes
  updateSubscriptionStatusInterval = () => {
    if (this.subscriptionInterval) clearInterval(this.subscriptionInterval)
    const self = this
    this.subscriptionInterval = setInterval(() => {
      self.updateSubscriptionStatus()
    }, 2 * 60 * 1000)
  }

  componentDidUpdate (prevProps) {
    const { user, getMe, getUserReleases, userId, companyId, constantsLoaded, onGetPositions } = this.props
    if (user) {
      const isUserIdChanged = userId && userId !== prevProps.userId
      const isCompanyIdChanged = companyId && companyId !== prevProps.companyId
      if (isUserIdChanged || isCompanyIdChanged) {
        this.updateSubscriptionStatusInterval()
      }
      if (isCompanyIdChanged) {
        this.updateCompanyInfo()
      }
      if (isUserIdChanged) {
        this.update()
        getMe()
        getUserReleases()
        this.checkAcceptedForms()
        this.checkUserInfo()
      } else if (user.has_ongoing_subscription !== path(['user', 'has_ongoing_subscription'], prevProps)) {
        this.updateSubscriptionStatus()
      }
    }
    if (!prevProps.constantsLoaded && constantsLoaded && companyId) {
      onGetPositions(companyId)
    }
  }

  componentWillUnmount () {
    if (this.subscriptionInterval) clearInterval(this.subscriptionInterval)
  }

  updateCompanyInfo = () => {
    const {
      companyId,
      getCompanyUsers,
      getCompanyProjects,
      onGetPositions,
      getCompanyDataUsage,
      getSystemTypes,
      constantsLoaded,
      isUserCustomer,
    } = this.props
    if (companyId) {
      if (!isUserCustomer) {
        getCompanyUsers(companyId)
        getCompanyProjects(companyId)
        getSystemTypes(companyId)
      }
      if (constantsLoaded) {
        onGetPositions(companyId)
      }
      getCompanyDataUsage(companyId)
    }
  }

  update = () => {
    const {
      isUserAdmin,
      getAllSystemTypes,
    } = this.props
    this.updateSubscriptionStatus()
    if (isUserAdmin) {
      getAllSystemTypes()
    }
  }

  updateSubscriptionStatus = () => {
    const {
      userId,
      companyId,
      getSubscriptions,
      getGrants,
    } = this.props
    if (companyId) getSubscriptions(companyId)
    if (userId) getGrants(userId)
  }

  userInfoValid = props => {
    const { user, companyPermissions, userPermissions } = props
    let isUserInfoValid = true
    let isCompanyInfoValid = true
    const userFields = [
      { name: 'first_name', label: 'first name' },
      { name: 'last_name', label: 'last name' },
      { name: 'country', label: 'country' },
    ].filter(({ name }) => !user[name])
    const companyFields = [
      // { name: 'invoice_address', label: 'invoice address' },
      { name: 'name', label: 'name' },
      { name: 'url', label: 'URL' },
      { name: 'industry', label: 'industry' },
    ].filter(({ name }) => !user.company[name])
    if (userPermissions.update && userFields.length > 0) {
      isUserInfoValid = false
    }
    if (companyPermissions.update && companyFields.length > 0) {
      isCompanyInfoValid = false
    }
    return {
      isUserInfoValid,
      isCompanyInfoValid,
      userFields,
      companyFields,
    }
  }

  checkUserInfo = async () => {
    const { getMeSuccess } = this.props
    if (this.checkUserInfoUpdatedTimeout) {
      clearTimeout(this.checkUserInfoUpdatedTimeout)
    }
    const self = this
    const { data: { data: user } } = await axiosAccounts.get('/me')
    const props = {
      companyPermissions: getCompanyPermissions(user, getCompanyId(user)),
      userPermissions: getUserPermissions(user, user),
      user,
    }
    const { isCompanyInfoValid, isUserInfoValid, userFields, companyFields } = this.userInfoValid(props)
    if (isCompanyInfoValid && isUserInfoValid) {
      this.setState(prevState => ({
        ...prevState,
        updateInfoDialogOpen: false,
        extra: {},
      }))
      getMeSuccess(user)
    } else {
      this.setState(prevState => ({
        ...prevState,
        updateInfoDialogOpen: true,
        extra: {
          userValid: isUserInfoValid,
          companyValid: isCompanyInfoValid,
          companyId: getCompanyId(user),
          userId: user.id,
          userFields,
          companyFields,
        },
      }))
      this.checkUserInfoUpdatedTimeout = setTimeout(() => {
        self.checkUserInfo()
      }, 2 * 60 * 1000)
    }
  }

  checkAcceptedTerms = () => {
    const { user, setAcceptTermsFormOpen } = this.props
    const self = this
    if (user && (typeof user.terms_accepted === 'boolean' && !user.terms_accepted)) {
      const cookie = this.getCookieByUser(lidarmillCookies.LIDARMILL_TERMS)
      if (cookie) {
        const expireDate = cookie.split('|')[1]
        const timeoutMs = new Date(expireDate).getTime() - new Date().getTime()
        if (this.timeout) {
          clearTimeout(this.timeout)
          this.timeout = setTimeout(() => self.checkAcceptedTerms(), timeoutMs)
        }
      } else {
        setAcceptTermsFormOpen(true)
      }
    }
  }

  checkAcceptedUpdate = () => {
    const { user } = this.props
    const self = this
    if (user) {
      const updatedAcceptedCookie = getCookie(lidarmillCookies.LIDARMILL_UPDATE_ACCEPTED)
      const updatedAcceptedStorage = getStorageItem(lidarmillCookies.LIDARMILL_UPDATE_ACCEPTED)
      if (!updatedAcceptedStorage && !updatedAcceptedCookie) {
        const updateCookie = this.getCookieByUser(lidarmillCookies.LIDARMILL_UPDATE)
        if (updateCookie) {
          const expireDate = updateCookie.split('|')[1]
          const timeoutMs = new Date(expireDate).getTime() - new Date().getTime()
          if (this.timeout) {
            clearTimeout(this.timeout)
            this.timeout = setTimeout(() => self.checkAcceptedUpdate(), timeoutMs)
          }
        } else {
          this.setState({ updateDialogOpen: true })
        }
      }
    }
  }

  getCookieByUser = cookieName => {
    const { userEmail } = this.props
    const cookies = document.cookie.split(';')
    return cookies.find(cookie => cookie.includes(`${cookieName}=${userEmail}`))
  }

  onAcceptTerms = () => {
    this.props.acceptTerms()
  }

  onAcceptUpdate = () => {
    document.cookie = `${lidarmillCookies.LIDARMILL_UPDATE_ACCEPTED}=16012021; max-age=${Infinity}`
    setStorageItem(lidarmillCookies.LIDARMILL_UPDATE_ACCEPTED, true)
    this.setState({ updateDialogOpen: false })
  }

  onCancelAcceptTerms = () => {
    this.onCancelDialog(lidarmillCookies.LIDARMILL_TERMS, this.props.setAcceptTermsFormOpen, this.checkAcceptedTerms)
  }

  onCancelUpdate = () => {
    this.onCancelDialog(lidarmillCookies.LIDARMILL_UPDATE, dialogOpen => this.setState({ updateDialogOpen: dialogOpen }), this.checkAcceptedUpdate)
  }

  onCancelDialog = (cookieName, setDialog, checkDialog) => {
    const { userEmail } = this.props
    const timeout = 120 * 60 * 1000 // 2 hours timeout
    const maxAge = 120 * 60 // 2 hours maxAge
    const expireDate = new Date(Date.now() + timeout)
    document.cookie = `${cookieName}=${userEmail}|${expireDate}; max-age=${maxAge}`
    this.timeout = setTimeout(() => checkDialog(), timeout)
    setDialog(false)
  }

  render () {
    const { location, isAcceptTermsDialogOpen } = this.props
    return (
      <React.Fragment>
        <CheckTokenDialog/>
        <UpdateInfoDialog
          open={this.state.updateInfoDialogOpen}
          {...this.state.extra}
          onSubmit={this.checkUserInfo}
        />
        <AcceptUpdateDialog
          open={this.state.updateDialogOpen}
          onClose={this.onCancelUpdate}
          onSubmit={this.onAcceptUpdate}
        />
        <AcceptTermsDialog
          open={location.pathname.includes('terms') ? false : isAcceptTermsDialogOpen}
          onClose={this.onCancelAcceptTerms}
          onSubmit={this.onAcceptTerms}
        />
        <UpdateCRSDialog />
        <Switch>
          <Route exact path='/' component={Login} />
          <Route exact path='/recovery/:token' component={Recover} />
          <Route exact path='/set_token' component={SetToken} />
          <Route exact path='/remove_cookies' component={RemoveCookies} />
          <Route exact path='/email_confirmation/:token' component={EmailConfirmation} />
          <RegisterRoute exact path='/companies/register_success' component={CompanyUserRegisterSuccess} />
          <RegisterRoute exact path='/register_success/' component={RegisterSuccess} />
          <RegisterRoute exact path='/register' component={() => {
            window.location.href = routeRegister()
            return null
          }} />
          <Redirect exact from='/dashboard' to='/dashboard/projects' query={{}} />
          <Redirect exact from='/projects' to='/dashboard/projects' query={{}} />
          <Route path='/dropbox' component={DropboxToken}/>
          <PrivateRoute exact path='/dashboard' component={Dashboard} />
          <PrivateRoute exact path='/dashboard/:tab' component={Dashboard} />
          <PrivateRoute exact path='/error' component={ErrorPage} />

          <PrivateRoute exact path='/positions' component={Positions} />
          <Redirect exact from='/projects/:project_id/:tab/:structure_id/' to='/projects/:project_id/:tab/:structure_id/general' query={{}} />
          <PrivateRoute exact path='/projects/:project_id/:tab/:structure_id/:pipeline_tab/:job_run_id' component={Project} />
          <PrivateRoute exact path='/projects/:project_id/:tab/:structure_id/:pipeline_tab' component={Project} />
          <PrivateRoute exact path='/projects/:project_id/:tab/:structure_id' component={Project} />
          <PrivateRoute exact path='/projects/:project_id/:tab' component={Project} />
          <PrivateRoute exact path='/projects/:project_id' component={Project} />
          <PrivateRoute exact path='/artifacts/:artifact_id/:tab' component={Artifact} />
          <PrivateRoute exact path='/artifacts/:artifact_id' component={Artifact} />
          <PrivateRoute exact path='/pipelines/:pipeline_id/:tab/:job_run_id' component={Pipeline} />
          <PrivateRoute exact path='/pipelines/:pipeline_id/:tab' component={Pipeline} />
          <PrivateRoute exact path='/pipelines/:pipeline_id' component={Pipeline} />
          <PrivateRoute exact path='/job_runs/:job_run_id' component={JobRun} />
          <PrivateRoute path='/transform' component={TransformationCRS} />

          <PrivateRoute exact path='/users/:user_id/:tab' component={User} />
          <PrivateRoute exact path='/users/:user_id' component={User} />
          <PrivateRoute exact path='/company/:company_id/share_projects' component={ShareProjects}/>
          <PrivateRoute exact path='/company/:company_id/:tab' component={Company}/>
          <PrivateRoute exact path='/company/:company_id' component={Company}/>
          <AdminRoute exact path='/statistics/:tab' component={Statistics} />
          <AdminRoute exact path='/statistics/:tab/:usage_tab' component={Statistics}/>
          <AdminRoute path='/statistics' component={Statistics} />
          <PrivateRoute component={ErrorPage}/>
        </Switch>
      </React.Fragment>
    )
  }
}

App.propTypes = {
  userId: PropTypes.string,
  userEmail: PropTypes.string,
  companyId: PropTypes.string,
  user: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  isUserAdmin: PropTypes.bool,
  isUserCustomer: PropTypes.bool,
  constantsLoaded: PropTypes.bool,
  isAcceptTermsDialogOpen: PropTypes.bool,
  users: PropTypes.array,
  getMe: PropTypes.func,
  logout: PropTypes.func,
  getUsers: PropTypes.func,
  getGrants: PropTypes.func,
  onGetUsers: PropTypes.func,
  onUrlChange: PropTypes.func,
  acceptTerms: PropTypes.func,
  getMeSuccess: PropTypes.func,
  getLoggedUser: PropTypes.func,
  onGetPositions: PropTypes.func,
  getSystemTypes: PropTypes.func,
  getGCPTemplate: PropTypes.func,
  getCompanyUsers: PropTypes.func,
  getSubscriptions: PropTypes.func,
  getAllSystemTypes: PropTypes.func,
  getCompanyProjects: PropTypes.func,
  getCompanyDataUsage: PropTypes.func,
  setAcceptTermsFormOpen: PropTypes.func,
  getUserReleases: PropTypes.func,
  getProducts: PropTypes.func,
}

const mapStateToProps = state => {
  const loggedUser = getLoggedUser(state)
  const constantsLoaded = isConstantsLoaded(state)
  return {
    isUserAdmin: isAdmin(state),
    isLoggedIn: isLoggedIn(state),
    isUserCustomer: isUserCompanyCustomer(loggedUser),
    users: getUsers(state),
    user: loggedUser,
    userId: loggedUser && loggedUser.id,
    userEmail: loggedUser && loggedUser.email,
    companyId: getCompanyId(loggedUser),
    isAcceptTermsDialogOpen: isAcceptTermsFormOpen(state),
    constantsLoaded,
  }
}

const mapDispatchToProps = dispatch => ({
  getSystemTypes: companyId => dispatch(CompaniesActions.getCompanySystemTypes(companyId)),
  getSubscriptions: companyId => dispatch(CompaniesActions.getCompanySubscriptions(companyId)),
  getCompanyUsers: companyId => dispatch(CompaniesActions.getCompanyUsers(companyId)),
  getCompanyProjects: companyId => dispatch(CompaniesActions.getCompanyProjects(companyId, false)),
  getCompanyDataUsage: companyId => dispatch(CompaniesActions.getCompanyDataUsage(companyId)),
  getGrants: userId => dispatch(GrantsActions.getGrants(userId)),
  onUrlChange: url => dispatch(UrlActions.changeUrl(url)),
  onGetPositions: companyId => dispatch(CompaniesActions.getPositions(companyId)),
  onGetUsers: () => dispatch(UsersActions.getUsers()),
  getMe: () => dispatch(UsersActions.getMe()),
  getUserReleases: () => dispatch(UsersActions.getUserReleases()),
  getMeSuccess: user => dispatch(UsersActions.getMeSuccess(user)),
  logout: () => dispatch(UsersActions.logout()),
  setAcceptTermsFormOpen: open => dispatch(UsersActions.setAcceptTermsFormOpen(open)),
  acceptTerms: () => dispatch(UsersActions.acceptTerms()),
  getAllSystemTypes: () => dispatch(AppActions.getAllSystemTypes()),
  getGCPTemplate: () => dispatch(AppActions.getGCPTemplate()),
  getProducts: () => dispatch(AppActions.getProducts()),
})

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(
    withRoot(withNamespaces('common')(App))
  )
)
