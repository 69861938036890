// External dependencies.
import React, { Fragment } from 'react'
import { path, equals } from 'ramda'
import { connect } from 'react-redux'
import ReactResizeDetector from 'react-resize-detector'
import { Trans } from 'react-i18next'
import { withRouter, Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'
// Material UI dependencies.
import { withStyles } from '@material-ui/core/styles'
import Collapse from '@material-ui/core/Collapse'
import AppBar from '@material-ui/core/AppBar'
import Grid from '@material-ui/core/Grid'
import Menu from '@material-ui/core/Menu'
import Hidden from '@material-ui/core/Hidden'
import IconButton from '@material-ui/core/IconButton'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Badge from '@material-ui/core/Badge'
// Icons
import AccountIcon from '@material-ui/icons/Person'
import TermsIcon from '@material-ui/icons/CopyrightOutlined'
import SubscriptionIcon from '@material-ui/icons/MonetizationOnOutlined'
import MenuIcon from '@material-ui/icons/MenuOutlined'
import HelpIcon from '@material-ui/icons/HelpOutlined'
import ReleasesIcon from '@material-ui/icons/Message'
import FaceIcon from '@material-ui/icons/FaceOutlined'
import PeopleIcon from '@material-ui/icons/PeopleOutlined'
import PaymentIcon from '@material-ui/icons/PaymentOutlined'
import BarChartIcon from '@material-ui/icons/BarChartOutlined'
import TransformIcon from '@material-ui/icons/TransformOutlined'
import PositionsIcon from '@material-ui/icons/MyLocationOutlined'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import LogoutIcon from '@material-ui/icons/ExitToApp'
import StorageIcon from '@material-ui/icons/Storage'
import WarningIcon from '@material-ui/icons/Warning'
import ShareIcon from '@material-ui/icons/Share'
// Project internal dependencies.
import SupportActions from 'modules/support/actions'
import { isSupportFormDialogOpened } from 'modules/support/selectors'
import UsersActions from 'modules/users/actions'
import { isAdmin as isUserAdmin, getLoggedUser, isCurrentUserCompanyCustomer, getActiveUser, isLoggedAsOtherUser, getTotalReleases } from 'modules/users/selectors'
import { getTabValue } from 'templates/tabs'
import { getBasicRouteUrl, Route as RouteName } from 'templates/route'
import { routeUser, routeCompanySubscription, routeCompany, routeCompanyDataUsage, routeUsers, routeCompanyShareProjects, routeTerms } from 'utils/routing'
import { getCompanyId } from 'utils/company'
import SupportFormDialog from 'components/Support/SupportFormDialog'
import Breadcrumbs from 'components/Breadcrumbs'
import Search from 'components/Search'
// Local deps
import Navigation from './Navigation'
import SubscriptionStatusAndQuota from './SubscriptionStatusAndQuota'
import StorageProgressBar from 'components/StorageProgressBar'
import { getExpiredSubscriptions, getExpireSoonSubscriptions } from 'utils/subscription'
import ProductReleasesDialog from 'components/ProductReleasesDialog'

const height = 42

const styles = theme => ({
  appBar: {
    // maxHeight: 150
    backgroundColor: theme.palette.header,
    paddingLeft: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(0),
    },
    zIndex: '1102',
  },
  secondaryBar: {
    zIndex: 0,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.header,
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
    // overflowX: 'auto'
  },
  me: {
    padding: theme.spacing(0, 1),
    borderColor: '#fff', // theme.palette.primary.light,
    border: 'none !important',
    // backgroundColor: '#e0e0e0', // theme.palette.primary.dark,
    height: '100%',
    width: 270,
  },
  meLabel: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  adminLink: {
    color: theme.palette.secondary.main,
  },
  menuButton: {
    marginRight: 20,
    [theme.breakpoints.up('lg')]: {
      display: 'none',
    },
  },
  tabsRoot: {
    fontWeight: '500',
  },
  tabsSelected: {
    background: 'rgba(0,0,0,0) !important',
  },
  subscriptionItem: {
    display: 'flex',
    width: '100%',
  },
  controls: {
    margin: 0,
    padding: 0,
    maxWidth: 'max-content',
    marginLeft: 'auto',
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },
  breadcrumbs: {
    display: 'flex',
    alignItems: 'center',
  },
  action: {
    color: '#fff',
    boxShadow: theme.mixins.boxShadow,
    backgroundColor: '#32373d !important',
    minHeight: height,
    borderRadius: 4,
    '&&:hover': {
      backgroundColor: '#3b4148 !important',
    },
    [theme.breakpoints.down('md')]: {
      width: '100% !important',
    },
  },
  search: {
    '@media (min-width: 1080px) and (max-width: 1607px)': {
      marginLeft: 0,
    },
  },
  box: {
    alignItems: 'center',
    '@media (min-width: 1080px)': {
      flexWrap: 'noWrap',
      marginLeft: 4
    }
  },
  boxAlign: {
    justifyContent: 'flex-end',
  },
  controlBox: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
    height: 'fit-content',
    '@media (min-width: 1080px)': {
      marginLeft: 0,
      flexWrap: 'noWrap',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: 'fit-content'
    },
  }
})

class Header extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      accountMenuOpen: false,
      subscriptionMenuOpen: true,
      notificationDialogOpen: false,
      tabsValue: 0,
      tabsLength: 0,
      anchorEl: null,
      fullSearchWidth: true,
      basicUrl: this.getBasicUrl(props),
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    if (!equals(this.props, nextProps) || !equals(this.state, nextState)) {
      return true
    }
    return false
  }

  componentDidMount () {
    this.updateTabValue(null, this.props)
    this.checkScreenWidthInRange()
    window.addEventListener('resize', this.checkScreenWidthInRange)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkScreenWidthInRange)
  }

  componentDidUpdate (prevProps) {
    this.updateTabValue(prevProps, this.props)
  }

  toggleAccountMenu = open => e => {
    this.setState({
      accountMenuOpen: open,
      anchorEl: open ? e.currentTarget : null,
    })
  }

  toggleMenu = name => e => {
    this.setState(prevState => ({
      [name]: !prevState[name],
    }))
  }

  getBasicUrl = props => {
    const { match } = props
    const url = match.url
    return url.split('/').filter(x => x)[0]
  }

  updateTabValue = (prevProps, props) => {
    const { tabsValue: stateTabsValue } = this.state
    const { match, tab: propsTab } = props
    // check the url to set the tab index value
    // due to reloading glitch
    const tab = match.params.tab || propsTab
    if (path(['match', 'params', 'tab'], prevProps) !== tab || tab) {
      const basicUrl = this.getBasicUrl(props)
      const tabsValue = getTabValue(match, basicUrl, tab, props)
      if (stateTabsValue !== tabsValue) this.setState({ tabsValue, basicUrl })
    }
  }

  // Checks if the current screen width is within the specified range
  checkScreenWidthInRange = () => {
    const width = window.innerWidth
    if (width >= 1080 && width <= 1607) {
      this.setState({ fullSearchWidth: false })
    } else {
      this.setState({ fullSearchWidth: true })
    }
  }

  onTabsChange = (e, tabsValue) => {
    e.preventDefault()
    this.setState({ tabsValue })
    this.props.history.push(e.currentTarget.id)
  }

  handleBreadcrumbClick = tabsValue => e => this.onTabsChange(e, tabsValue)
  handleMenuItemClick = routeName => e => this.props.history.push(getBasicRouteUrl(routeName))
  handleCompanyClick = () => this.props.history.push(routeCompany(this.props.user.company.id))
  toggleOpenNotificationDialog = () => this.setState(prevState => ({ notificationDialogOpen: !prevState.notificationDialogOpen }))

  render () {
    const {
      classes,
      isSupportDialogOpened,
      closeSupportFormDialog,
      breadcrumbs,
      match,
      title,
      isAdmin,
      projectPage,
      totalReleases,
    } = this.props
    const additionalSearchStyles = this.state.tabsLength > 2 ? classes.search : ''
    const additionalBoxStyles = this.state.tabsLength === 0 ? classes.boxAlign : ''

    return (
      <Fragment>
        <ProductReleasesDialog
          open={this.state.notificationDialogOpen}
          onClose={this.toggleOpenNotificationDialog}
        />
        <SupportFormDialog
          openDialog={isSupportDialogOpened}
          handleClose={closeSupportFormDialog}
          projectPage={projectPage}
        />
        <AppBar color='primary' position='sticky' elevation={0} classes={{ root: classes.appBar }}>
          <ReactResizeDetector refreshMode='debounce' refreshRate={0} handleHeight handleWidth onResize={this.props.onChangeHeaderHeight}>
            <div key='header' id='header'>
              <Toolbar style={{ padding: '0px 0px 0px 12px' }}>
                <Grid container style={{ flexWrap: 'nowrap', padding: 0 }}>
                  <Grid item>
                    <Hidden only={['xl']} implementation='css'>
                      <IconButton
                        color='inherit'
                        aria-label='Open drawer'
                        onClick={this.props.onSideMenuChange}
                        className={classes.menuButton}>
                        <MenuIcon />
                      </IconButton>
                    </Hidden>
                  </Grid>
                  <Grid item className={classes.breadcrumbs}>
                    <Breadcrumbs
                      onClick={this.handleBreadcrumbClick}
                      breadcrumbsData={breadcrumbs}
                      match={match}
                      extraProps={this.props}
                    />
                  </Grid>
                  <Grid item style={{ display: 'flex', marginLeft: 'auto', alignItems: 'center' }}>
                    {title}
                  </Grid>
                </Grid>
              </Toolbar>
              <div style={{ width: '100%', flexGrow: 1 }}>
                <AppBar
                  className={classes.secondaryBar}
                  color='primary'
                  position='static'
                  elevation={0}>
                  <Grid item xs={12} container justifyContent="space-between" className={classNames(classes.box, additionalBoxStyles)}>
                  <Hidden only={['md', 'sm', 'xs']}>
                    <Navigation
                      match={this.props.match}
                      classes={this.props.classes}
                      tabsValue={this.state.tabsValue}
                      isUserCustomer={this.props.isUserCustomer}
                      onTabsChange={this.onTabsChange}
                      setTabsLength={length => this.setState({ tabsLength: length })}
                    />
                    </Hidden>
                    <div className={classes.controlBox}>
                      <Grid item container spacing={1} xs={12} justifyContent="space-between" className={classNames(classes.box, classes.controls)}>
                        {isAdmin &&
                        <React.Fragment>
                            <Hidden only={['lg', 'xl']}><Grid item xs={12}><Search /></Grid></Hidden>
                            <Hidden mdDown><Grid item className={additionalSearchStyles}><Search fullWidth={this.state.tabsLength < 3 || this.state.fullSearchWidth} /></Grid></Hidden>
                          </React.Fragment>
                        }
                    <Hidden only={['lg', 'xl']}>
                      <Grid item xs={2}>
                        <Tooltip title={<Trans i18nKey='navigator.header.help' />}>
                              <IconButton className={classes.action} style={{ width: '100%', minWidth: 50 }} onClick={this.props.openSupportFormDialog}>
                            <HelpIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Hidden>
                    <Hidden mdDown>
                      <Grid item>
                        <Tooltip title={<Trans i18nKey='navigator.header.help' />}>
                          <IconButton className={classes.action} style={{ width: '100%', minWidth: 50 }} onClick={this.props.openSupportFormDialog}>
                            <HelpIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Hidden>
                    <Hidden only={['lg', 'xl']}>
                      <Grid item xs={2}>
                        <Badge badgeContent={totalReleases} color="primary" style={{ width: '100%' }}>
                          <Tooltip title={<Trans i18nKey='navigator.header.notification' />}>
                            <IconButton className={classes.action} style={{ width: '100%', minWidth: 50 }} onClick={this.toggleOpenNotificationDialog}>
                              <ReleasesIcon />
                            </IconButton>
                          </Tooltip>
                        </Badge>
                      </Grid>
                    </Hidden>
                    <Hidden mdDown>
                      <Grid item>
                        <Badge badgeContent={totalReleases} color="primary" style={{ width: '100%' }}>
                          <Tooltip title={<Trans i18nKey='navigator.header.notification' />}>
                            <IconButton className={classes.action} style={{ width: '100%', minWidth: 50 }} onClick={this.toggleOpenNotificationDialog}>
                              <ReleasesIcon />
                            </IconButton>
                          </Tooltip>
                        </Badge>
                      </Grid>
                    </Hidden>
                    <React.Fragment>
                      <Hidden only={['lg', 'xl']}><Grid item xs={8}>{renderMenu.bind(this, 'dashboard')()}</Grid></Hidden>
                      <Hidden mdDown><Grid item>{renderMenu.bind(this, 'dashboard')()}</Grid></Hidden>
                    </React.Fragment>
                      </Grid>
                    </div>
                  </Grid>
                  <Hidden lgUp>
                    <Navigation
                      match={this.props.match}
                      classes={this.props.classes}
                      tabsValue={this.state.tabsValue}
                      isUserCustomer={this.props.isUserCustomer}
                      onTabsChange={this.onTabsChange}
                      fullWidth
                    />
                  </Hidden>
                </AppBar>
              </div>
            </div>
          </ReactResizeDetector>
        </AppBar>
      </Fragment>
    )
  }
}

function renderMenu () {
  const { anchorEl, subscriptionMenuOpen } = this.state
  const {
    onLogout,
    user,
    isAdmin,
    isUserCustomer,
    classes,
    userId,
    activeCompanyId: companyId,
    dataUsageCompanyId,
    isLoggedAsAnotherUser,
    showWarning,
  } = this.props
  const userEmail = user.email
  return (
    <React.Fragment>
      <Button
        aria-controls='user-menu'
        aria-haspopup='true'
        variant='outlined'
        className={classNames(classes.action, classes.me)}
        onClick={this.toggleAccountMenu(true)}
        startIcon={<AccountIcon style={{ color: isLoggedAsAnotherUser ? 'red' : 'inherit' }}/>}
      >
        {showWarning && <WarningIcon style={{ color: 'orange' }}/>}
        <Hidden xsDown>
          <span style={{ color: isLoggedAsAnotherUser ? 'red' : 'inherit' }} className={classes.meLabel}>
            {isLoggedAsAnotherUser ? `Be aware. Logged as ${userEmail}. ` : userEmail}
          </span>
        </Hidden>
        {anchorEl ? <ExpandLessIcon/> : <ExpandMoreIcon /> }
      </Button>
      <Menu
        id='user-menu'
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        keepMounted
        anchorOrigin={{
          vertical: 42,
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchorEl}
        onClose={this.toggleAccountMenu(false)}>
        <MenuItem
          component={Link}
          to={routeCompanyDataUsage(companyId)}>
          <ListItemIcon><StorageIcon /></ListItemIcon>
          <ListItemText classes={{ primary: classes.subscriptionItem }}>
            Storage usage
          </ListItemText>
        </MenuItem>
        <div style={{
          padding: '6px 16px',
          borderBottom: '1px solid #efefef',
        }}>
          <StorageProgressBar labels={false} companyId={dataUsageCompanyId} />
        </div>
        <MenuItem
          onClick={this.toggleMenu('subscriptionMenuOpen')}>
          <ListItemIcon><SubscriptionIcon /></ListItemIcon>
          <ListItemText classes={{ primary: classes.subscriptionItem }}>
            <Trans i18nKey='app.menu.userMenu.subscription' />
            {subscriptionMenuOpen
              ? <ExpandLessIcon style={{ marginLeft: 'auto' }}/>
              : <ExpandMoreIcon style={{ marginLeft: 'auto' }}/>
            }
          </ListItemText>
        </MenuItem>
        <Collapse in={subscriptionMenuOpen}>
          <SubscriptionStatusAndQuota userId={userId} companyId={companyId}/>
        </Collapse>
        <MenuItem
          component='a'
          href={routeUser(this.props.user.id)}>
          <ListItemIcon><FaceIcon /></ListItemIcon>
          <ListItemText><Trans i18nKey='app.menu.userMenu.profile' /></ListItemText>
        </MenuItem>
        { !isUserCustomer &&
          <MenuItem
            component='a'
            href={routeCompanySubscription(getCompanyId(this.props.user))}>
            <ListItemIcon><PaymentIcon /></ListItemIcon>
            <ListItemText><Trans i18nKey='app.menu.userMenu.transactions' /></ListItemText>
          </MenuItem>
        }
        { !isUserCustomer &&
          <MenuItem
            component='a'
            href={routeCompanyShareProjects(getCompanyId(this.props.user))}>
            <ListItemIcon><ShareIcon /></ListItemIcon>
            <ListItemText><Trans i18nKey='app.menu.userMenu.shareProjects' /></ListItemText>
          </MenuItem>
        }
        <MenuItem
          component='a'
          href={
            isAdmin
              ? routeUsers()
              : routeCompany(getCompanyId(this.props.user))
          }>
          <ListItemIcon><PeopleIcon /></ListItemIcon>
          <ListItemText><Trans i18nKey='app.menu.userMenu.users' /></ListItemText>
        </MenuItem>
        {
          isAdmin &&
          <MenuItem
            component={Link}
            to={getBasicRouteUrl(RouteName.STATISTICS)}>
            <ListItemIcon><BarChartIcon /></ListItemIcon>
            <ListItemText><Trans i18nKey='app.menu.userMenu.statistics' /></ListItemText>
          </MenuItem>
        }
        <MenuItem
          component={Link}
          to={getBasicRouteUrl(RouteName.POSITIONS)}>
          <ListItemIcon><PositionsIcon /></ListItemIcon>
          <ListItemText><Trans i18nKey='app.menu.userMenu.positions' /></ListItemText>
        </MenuItem>
        <MenuItem
          component={Link}
          to={getBasicRouteUrl(RouteName.TRANSFORM)}>
          <ListItemIcon><TransformIcon /></ListItemIcon>
          <ListItemText><Trans i18nKey='app.menu.userMenu.transformation' /></ListItemText>
        </MenuItem>
        <MenuItem
          component='a'
          href={routeTerms()}>
          <ListItemIcon><TermsIcon /></ListItemIcon>
          <ListItemText><Trans i18nKey='app.menu.userMenu.terms' /></ListItemText>
        </MenuItem>
        <MenuItem onClick={onLogout}>
          <ListItemIcon><LogoutIcon /></ListItemIcon>
          <ListItemText><Trans i18nKey='app.menu.userMenu.logout' /></ListItemText>
        </MenuItem>
      </Menu>
    </React.Fragment>
  )
}

Header.propTypes = {
  totalReleases: PropTypes.number,
  isAdmin: PropTypes.bool,
  isUserCustomer: PropTypes.bool,
  projectPage: PropTypes.bool,
  showWarning: PropTypes.bool,
  isSupportDialogOpened: PropTypes.bool,
  user: PropTypes.object,
  match: PropTypes.object,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object,
  breadcrumbs: PropTypes.object,
  onLogout: PropTypes.func,
  onSideMenuChange: PropTypes.func,
  onChangeHeaderHeight: PropTypes.func,
  openSupportFormDialog: PropTypes.func,
  closeSupportFormDialog: PropTypes.func,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ]),
}

Header.defaultPropTypes = {
  classes: {},
  match: {},
  history: {},
  breadcrumbsData: {
    breadcrumbs: [],
    basicUrl: '',
  },
}

const mapStateToProps = (state, props) => {
  /*
  const { location } = props
  const currentProject = getCurrentProject(state)
  const users = getUsers(state)
  const user = getLoggedUser(state)

  const match = getMatch(location.pathname)
  const url = match.url
  let breadcrumbs = []
  let title = ''
  // This stands for basically users, projects, statistics, ...etc
  const firstUrlSegment = url.split('/').filter(x => x)[0]
  if (firstUrlSegment === RouteName.PROJECTS) {
    const { user: projectOwnerUser = {} } = currentProject.project
    const ownerUser = users.find(_user => _user.id === projectOwnerUser.id)
    title = ownerUser && <Typography
        variant={'body1'}
        align={'right'}
        color={'inherit'}>
        {`Viewing ${ownerUser.first_name} ${ownerUser.last_name} ${ownerUser.email}`}
      </Typography>
      breadcrumbs = getBreadcrumbs({ state, ...props }, RouteName.PROJECTS, getTabName(match, RouteName.PROJECTS))
  }
  */

  const { activeUserId, activeCompanyId } = props
  const loggedUser = getLoggedUser(state)
  const userId = activeUserId || (loggedUser && loggedUser.id)
  const isAdmin = isUserAdmin(state)
  const isUserCustomer = isCurrentUserCompanyCustomer(state)
  const isLoggedAsAnotherUser = isLoggedAsOtherUser(state)

  const companyId = activeCompanyId || getCompanyId(activeUserId ? getActiveUser(state) : loggedUser)
  const expiredSubscriptions = getExpiredSubscriptions(state, companyId)
  const expireSoonSubscriptions = getExpireSoonSubscriptions(state, companyId)

  return {
    totalReleases: getTotalReleases(state),
    userId,
    isAdmin,
    isUserCustomer,
    user: getLoggedUser(state) || {},
    dataUsageCompanyId: activeCompanyId || getCompanyId(activeUserId ? getActiveUser(state) : loggedUser),
    isSupportDialogOpened: isSupportFormDialogOpened(state),
    isLoggedAsAnotherUser,
    showWarning: !isAdmin && (expiredSubscriptions.length + expireSoonSubscriptions.length > 0),
  }
}

const mapDispatchToProps = dispatch => ({
  onLogout: () => dispatch(UsersActions.logout()),
  openSupportFormDialog: () => dispatch(SupportActions.openSupportFormDialog()),
  closeSupportFormDialog: () => dispatch(SupportActions.closeSupportFormDialog()),
})

export default withRouter(
  withStyles(styles, { withTheme: true })(
    connect(mapStateToProps, mapDispatchToProps)(Header),
  )
)
